<template>
<ion-card class="wise-infocard">
    <div class="wise-infocard_inner">
        <div class="wise-infocard_header">
            <slot name="header">

            </slot>
        </div>
        <div class="wise-infocard_main">
            <slot></slot>
        </div>
        <div class="wise-infocard_footer">
            <slot name="footer">

            </slot>
        </div>
    </div>
</ion-card>
</template>

<script>
export default {

}
</script>