<template>
<ion-page>
    <ion-content class="ion-padding" :class="contentClass">
        <slot name="header"></slot>
        <div class="wise-page-root">
            <div class="wise-page-spacer"></div>
            <div>
                <slot></slot>
            </div>
        </div>
    </ion-content>
</ion-page>
</template>

<script>
export default {
    props: {
        contentClass: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
}
</script>

<style scoped>
.back-button + .wise-page-root {
    min-height: calc(100% - 40px - var(--ion-padding, 16px));
}

.wise-page-root {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - var(--ion-padding, 16px));
}

.wise-page-spacer {
    flex-grow: 1;
}
</style>