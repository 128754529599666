import moment from 'moment'
let localization = {"twelveHourClock":false,"dateFormatFull":"DD.MM.YYYY","dateFormatLite":"DD.MM"};

const DATETIME_DMYHIS = 0;
const DATETIME_DMY = 1;
const DATETIME_DM = 2;
const DATETIME_DAY_NAME = 3;
const DATETIME_DAY_NAME_SHORT = 4;
const DATETIME_HIS = 5;
const DATETIME_HI = 8;
const DATETIME_DMYHI = 9;

export function getDateFormat(format) {
    let timeFormat = 'HH:mm:ss';

    if(localization.twelveHourClock) {
        timeFormat = 'hh:mm:ss a';
    }

    switch(format) {
        case DATETIME_DMYHIS:
            return `${ localization.dateFormatFull } ${ timeFormat }`;
        
        case DATETIME_DMYHI:
            timeFormat = 'HH:mm';
            
            if(localization.twelveHourClock) {
                timeFormat = 'hh:mm a';
            }
            
            return `${ localization.dateFormatFull } ${ timeFormat }`;
        
        case DATETIME_DMY:
            return localization.dateFormatFull;

        case DATETIME_DM:
            return localization.dateFormatLite;

        case DATETIME_DAY_NAME:
            return 'dddd';

        case DATETIME_DAY_NAME_SHORT:
            return 'dd';

        case DATETIME_HIS:
            return timeFormat;
        
        case DATETIME_HI:
            timeFormat = 'HH:mm';
            
            if(localization.twelveHourClock) {
                timeFormat = 'hh:mm a';
            }
            
            return timeFormat;

      default:
        throw `Unknown date/time format: ${ format }`;
    }
}
//, utcOffset = 120
export function formatDateTime(format, time = null) {
    if(time === null) {
        time = new Date();
    }
    
    let date;
    
    // check if time is a unix timestamp/number
    // php short form needs the X format whereas the JS form doesn't
    // check this logic again before Sat Nov 20 2286 19:46:40 GMT+0200
    if (isFinite(time) && (''+time).length === 10) {
        date = moment(time, 'X');
    } else {
        date = moment(time);
    }
    //.utcOffset(utcOffset)

    if(!isFinite(format)) {
        return date.format(format);
    }
    
    let timeFormat = 'HH:mm:ss';

    if(localization.twelveHourClock) {
        timeFormat = 'hh:mm:ss a';
    }

    switch(format) {
        case DATETIME_DMYHIS:
            return date.format(`${ date.format(localization.dateFormatFull) } ${ timeFormat }`)
        
        case DATETIME_DMYHI:
            timeFormat = 'HH:mm';
            
            if(localization.twelveHourClock) {
                timeFormat = 'hh:mm a';
            }
            
            return date.format(`${ date.format(localization.dateFormatFull) } ${ timeFormat }`)
        
        case DATETIME_DMY:
            return date.format(localization.dateFormatFull);

        case DATETIME_DM:
            return date.format(localization.dateFormatLite);

        case DATETIME_DAY_NAME:
            return date.format('dddd');

        case DATETIME_DAY_NAME_SHORT:
            return date.format('dd');

        case DATETIME_HIS:
            return date.format(timeFormat);
        
        case DATETIME_HI:
            timeFormat = 'HH:mm';
            
            if(localization.twelveHourClock) {
                timeFormat = 'hh:mm a';
            }
            
            return date.format(timeFormat);

      default:
        throw `Unknown date/time format: ${ format }`;
    }

    //throw `Unknown error converting date/time format`;
}

export const dateFormat = {
    ...localization,
    DATETIME_DMYHIS,
    DATETIME_DMY,
    DATETIME_DM,
    DATETIME_DAY_NAME,
    DATETIME_DAY_NAME_SHORT,
    DATETIME_HIS,
    DATETIME_HI,
    DATETIME_DMYHI
}