<template>
<div class="dataTables_wrapper wn-datatable">
    <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped table-condensed no-footer" 
                style="border-top: 1px solid #ddd;margin-bottom:0px;">
            <thead>
                <tr v-show="!!$slots.filters">
                    <slot name="filters"></slot>
                </tr>
                <tr>
                    <slot name="head"></slot>
                </tr>
            </thead>
            <tbody>
            <template v-for="(item, index) in paginatedItems">
                <slot :item="item" :index="index"></slot>
            </template>
            <tr v-show="items.length === 0">
                <slot name="empty"></slot>
            </tr>
            <slot name="footer"></slot>
            </tbody>
        </table>
    </div>
    <div class="datatable-footer" >
        <div class="" style="display:flex;">
            <select
                class="form-control pagination" 
                style="width: auto; margin-right: 20px;"
                :value="pagingOption"
                @input="onPagingChange">
                <option v-for="opt in pagingOptions" 
                    :key="opt" 
                    :value="opt">{{ opt > -1 ? opt : $_locale('all') }}</option>
            </select>
            <nav class="" style="margin-left: auto;">
                <ul class="pagination">
                    <li class="clickable" 
                        v-if="page > 1"
                        @click="changePage(page - 1)">
                        <span aria-hidden="true">«</span>
                    </li>
                    <li class="clickable" 
                        v-for="(pageNum, index) in pages"
                        :key="index"
                        :class="{ 'active': (page == pageNum) }" 
                        @click="changePage(pageNum)" >
                        <span>{{ pageNum }}</span>
                    </li>
                    <li class="clickable" 
                        v-if="page < pages.length"
                        @click="changePage(page + 1)">
                        <span aria-hidden="true">»</span>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
</template>

<script>
/* eslint-disable */
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        perPage: {
            type: Number,
            default: 10,
        },
        defaultPage: {
            type: Number,
            default: null,
        },
        pagingOptions: {
            type: Array,
            default: () => [10, 50, -1],
        },
        rowBinding: {
            type: Object,
            required: false,
            default: () => {},
        },
        sortTrigger: {
            type: String,
            required: false,
        },
    },
    data: () =>({
        timeStamp: Date.now(),
        page: 1,
        pagingOption: null,
        perPageActive: 10,
    }),
    computed: {
        paginatedItems() {
            // you may think that timeStamp is in no use but Vue will recognize it as a depedency
            // so that when it changes, it triggers a re-render in case of sorting the items array
            // that is also why the sortTrigger setting is used for, when it changes it refreshes
            // the timeStamp and it is also possible to refresh it manually via refenrence method
            const timeStamp = this.timeStamp;
            const items = this.items;
            const page = this.page;
            const perPageActive = this.perPageActive;

            return items.slice((page - 1) * perPageActive, page * perPageActive);
        },
        pages() {
            const items = this.items;
            const page = this.page;
            const perPageActive = this.perPageActive;
            const pagingOption = this.pagingOption;

            if (items.length === 0 || pagingOption === -1) {
                return [1];
            }
            
            const lastPage = Math.ceil(items.length / perPageActive);
            
            return (new Array(lastPage)).fill(null)
                .map((row, index) => {
                    return index + 1;
                })
                .filter((row, index) => {
                    return index == 0 || index == (lastPage - 1) || (index > 0 && Math.abs(index - page) < 3)
                })
        },
        bindRow() {
            const bindings = this.rowBinding;

            if (!bindings) {
                return () => ({});
            }

            return (item, index) => {
                const binding = {};
                
                Object.keys(bindings).forEach((bindName) => {
                    const prop = bindings[bindName];

                    if (bindName.includes('data-')) {
                        binding[bindName] = item[prop];
                    } else {
                        if (typeof prop === 'function') {
                            binding[bindName] = prop(item, index);
                        } else {
                            binding[bindName] = prop;
                        }
                    }
                });

                return binding;
            };
        },
    },
    watch: {
        perPage(val) {
            this.perPageActive = val;
        },
        sortTrigger() {
            this.refresh()
        }
    },
    methods: {
        refresh() {
            this.timeStamp = Date.now();
        },
        changePage(value) {
            this.page = value;
        },
        onPagingChange($event) {
            const value = parseInt($event.target.value);

            if (value === -1) {
                this.perPageActive = this.items.length;
            } else {
                this.perPageActive = value;
            }
            
            this.pagingOption = value;
        },
    },
    mounted() {
        if (typeof defaultPage === 'number') {
            this.page = this.defaultPage;
        }

        this.perPageActive = this.perPage;
        this.pagingOption = this.perPage;
    }
}
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
</style>