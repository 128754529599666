<template>
    <ion-button class="circle back-button" color="secondary"
        v-if="showButton" 
        @click="action">
        <i class="wn-icon arrow-left"></i>
    </ion-button>
</template>

<script>
export default {
    props: {
        onClick: {
            type: Function,
            required: false,
            default: null,
        },
        defaultPath: {
            type: String,
            required: false,
            default: null,
        }
    },
    name: 'WiseBackButton',
    computed: {
        showButton() {
            if (typeof this.onClick === 'function') return true;
            if (this.routerStackIsNotEmpty) return true;
            if (typeof this.default === 'string') return true;

            return false;
        },
        routerStackIsNotEmpty() {
            const { state, length } = window.history
            const { back, current } = state

            if (back === current) {
                return length > 2;
            }

            return length > 1;
        },
        goBackSteps() {
            const { state } = window.history
            const { back, current } = state

            return back === current ? -2 : -1;
        },
    },
    methods: {
        action() {
            if (typeof this.onClick === 'function') {
                this.onClick()
            } else if (this.routerStackIsNotEmpty) {
                this.$router.go(this.goBackSteps)
            } else if (this.defaultPath) {
                this.$router.replace(this.defaultPath)
            }
        },
    },
}
</script>