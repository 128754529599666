//import router from './router'
// notifications to app front page from the developer to the user
// these are visible until the user confirms/uses action 
export default {
    'default-fi': [
        /*
        {
            id: 'wisegolf-1729146756642',
            color: 'primary',
            title: 'Uutta tässä versiossa',
            content: 'Voit nyt valita haluatko ilmoituksia lähdön muutoksista!',
            callback: () => router.push('/golf/account/reservationsettings'),
            expired: new Date('2024-11-01 00:00:00') < new Date()
        },
        {
            id: 'wisegolf-1729146756643',
            color: 'primary',
            title: 'Uutta tässä versiossa',
            content: 'Käy tarkistamassa uudet push-ilmoitusasetukset Omat tiedot-sivulla.',
            callback: () => router.push('/golf/account/notifications'),
            expired: new Date('2024-11-01 00:00:00') < new Date()
        },
        */
    ],
}