import { Preferences } from '@capacitor/preferences';
import { mapState, mapMutations } from "vuex";

export default {
    computed: {
		...mapState({
			settings: state => state.common.settings,
		}),
		availableHosts() {
			return this.settings.hosts.filter(row => !row.disableLogin);
		},
	},
    methods: {
		...mapMutations({
			mutateSelectedHost: "common/mutateSelectedHost",
		}),
		async setHost(event) {
			const host = event.target.value;

			await Preferences.set({ key: `selectedHost-${this.settings.appId}`, value: host });
			this.mutateSelectedHost(host);
		},
    },
    async mounted() {
		// If only one host in settings set this host to selected host
		if(this.settings.hosts.length === 1) this.mutateSelectedHost(this.settings.hosts[0].name)
	},
}