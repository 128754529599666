import { alertController } from '@ionic/vue'

export async function wiseAlert(settings) {
	const alert = await alertController
	.create({
		mode: 'ios',
		...settings,
	});

	await alert.present();

	return alert;
}