<template>
	<ion-page>
		<ion-tabs>			
			<ion-router-outlet></ion-router-outlet>
			<ion-tab-bar mode="ios" color="dark" slot="bottom" v-show="!$route.path.includes('welcome') && loggedIn && showTabs">
				<ion-tab-button class="tab-activeclub" tab="ecomfront" href="/golf/front">
					<template v-if="activeClub.name === selectedHost">
						<i class="wn-icon home" />
					</template>
					<template v-else>
						<i class="wn-icon globe" />
					</template>
					<ion-label>{{ displayName }}</ion-label>
				</ion-tab-button> 

				<ion-tab-button tab="golfreservations" href="/golf/reservation">
					<i class="wn-icon golf-tee"></i>
					<ion-label>{{ $t('tabs.golfproducts') }}</ion-label>
				</ion-tab-button>

				<ion-tab-button tab="today" href="/golf/today">
					<i class="wn-icon notification"></i>
					<ion-label>{{$t('tabs.current')}}</ion-label>
				</ion-tab-button>

				<ion-tab-button tab="account" href="/golf/account">
					<i class="wn-icon account"></i>
					<ion-label>{{ $t('tabs.accountInfo') }}</ion-label>
				</ion-tab-button>
			</ion-tab-bar>
		</ion-tabs>
	</ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonRouterOutlet } from "@ionic/vue";
import { mapState } from "vuex";
import useGolfState from '@/WiseEcom/mixins/GolfState.js'
import { 
    golfClubs
} from '@/WiseEcom/mixins/golfClubs.js'
import useWiseTabs from '@/helpers/WiseTabs.js'

export default {
	name: 'EcomTabs',
	setup() {
        const { showTabs } = useWiseTabs()
		const { 
			associatedClubs,
			selectedClub
		} = useGolfState()

        return { 
			showTabs, 
			associatedClubs,
			selectedClub,
		}
    },	
	components: { IonTabs, IonTabBar, IonTabButton, IonRouterOutlet },
	data: () => ({
	}),
	computed: {
		...mapState({
			loggedIn: state => state.user.loggedIn,
			selectedHost: state => state.common.selectedHost,
		}),
		activeClub() {
			const [ selectedClub ] = this.associatedClubs.filter(row => row.name === this.selectedClub);
			
			if (selectedClub) {
				return selectedClub;
			}

            return { name: this.$_locale('home') };
		},
		displayName() {
			// active club display name unless 
			const [ activeClub ] = golfClubs.filter(row => row.clubId == this.activeClub.golfClubId)
			
			if (activeClub) {
				return activeClub.abbreviation
			}

			return this.$_locale('home');
		},
	},
	methods: {
	},
};
</script>

<style scoped>
.fab-vertical-bottom.fab-edge {
    bottom: 10px !important;
}

ion-button .button-inner,
ion-fab-button .button-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
}

ion-button .button-inner ion-icon,
ion-fab-button .button-inner ion-icon {
	font-size: 22px;
}

ion-button .button-inner ion-label,
ion-fab-button .button-inner ion-label {
	font-size: 12px;
}

.custom-button {
    width: 160px;
    height: 128px;
    border-radius: 37%;
    background: red;
    position: fixed;
    left: calc(50% - 80px);
    bottom: -44px;
    z-index: 11;
    display: none;
}
</style>