//import moment from 'moment';
import EcomService from './EcomService.js';

const stateFactory = () => ({
    reservationProducts: [],
    product: null,
    cart: [],
    cartCreated: null,
    showProductPrice: true,
    shareOwners: [],
    returnView: null,
    cartReservationInvalidDeadline: false,
    cartHasReservations: false,
    cachedCartData: [], // for creating new cart, resetting EcomCheckout
})

export default {
    namespaced: true,
    state: stateFactory(),
    getters: {
        EcomService: () => EcomService,
        reservationProducts: (state) => state.reservationProducts,
        product: (state) => state.product || {},
        showProductPrice: (state) => state.showProductPrice,
        cart: (state) => state.cart,
        cartCreated: (state) => state.cartCreated,
        cartIsPopulated(state) {
            return state.cart.length > 0;
        },
        cartHasReservations(state) {
            return state.cartHasReservations;
        },
        cartReservationInvalidDeadline(state) {
            return state.cartReservationInvalidDeadline;
        },
        cachedCartData: (state) => state.cachedCartData,
        orderLinkUrl: () => (orderId, hash = null) => {
            const { adminUrl, baseUrl } = EcomService.getSettings();
            
            if (!adminUrl && !baseUrl) return '';

            if (hash === null) {
                return `${ adminUrl }/?action=orders&subact=view&orderid=${ orderId }`
            }
            
            return `${ baseUrl }/?action=customeraccount&method=vieworder&orderid=${ orderId }&hashid=${ hash }`
        },
        shareOwners: (state) => state.shareOwners,
        returnView: (state) => state.returnView,
    },
    mutations: {
        reset(state) {
            const defaults = stateFactory();
            const resetExceptions = [
                'returnView', 
                'cartReservationInvalidDeadline', 
                'cartHasReservations',
                'cartCreated',
                'cachedCartData'
            ];
            
            Object.keys(defaults).forEach(key => {
                if (resetExceptions.includes(key)) return;

                const value = defaults[key];

                state[key] = value;
            })
        },
        setReturnView(state, view) {
            state.returnView = view;
        },
        setCartProducts(state, rows) {
            state.cart = rows;
            
            if (rows.length === 0) {
                state.cartReservationInvalidDeadline = false;
                state.cartHasReservations = false;
                state.cartCreated = null;
            } else {
                state.cartHasReservations = rows.filter(row => row.type == 6).length > 0
                state.cartCreated = rows[0].dateCreated
            }
            //console.log('setCartProducts', rows.length, state.cartReservationInvalidDeadline, state.cartHasReservations, state.cartCreated)
        },
        setCachedCartData(state, rows) {
            state.cachedCartData = rows;
        },
        setCartReservationInvalidDeadline(state, value) {
            state.cartReservationInvalidDeadline = value;
        },
        setCartHasReservations(state, value) {
            state.cartHasReservations = value;
        },
        setCartDateCreated(state, value) {
            state.cartCreated = value;
        },
        setActiveProduct(state, value) {
            value.price = -(-value.price);

            if (value.price === 0) {
                state.showProductPrice = false;
            }
            
            state.product = value;
        },
    },
    actions: {
        setEcomServiceSettings(context, settings) {
            EcomService.setSettings(settings)

            return Promise.resolve();
        },
        async addToCart({ commit }, cartData) {
            commit('setCachedCartData', cartData)
            
            return await EcomService.addToCart(cartData)
        },
        async getMyCart({ commit }) {
            const response = await EcomService.getMyCart()
            const { data } = response;
            //console.log('getMyCart', data.rows)
            if (Array.isArray(data.rows)) {
                commit('setCartProducts', data.rows)
            }
            
            return response;
        },
        deleteCartOrder({ state }) {
            return Promise.all([
                EcomService.deleteCartOrder(),
                ...state.cart.map(product => EcomService.removeFromCart(product.cartProductId))
            ])
        },
        getShareOwners({ state }) {
            if (state.shareOwners.length > 0) {
                // mimic ajax response
                return Promise.resolve({
                    data: {
                        rows: state.shareOwners
                    }
                })
            }

            return EcomService.getShareOwners()
            .then(response => {
                const { data } = response;
                
                if (Array.isArray(data.rows)) {
                    state.shareOwners = data.rows;
                }
                
                return response;
            })
        },
        async getReservationProducts({ state }) {
            const { data } = await EcomService.getReservationProducts()
            
            if (Array.isArray(data.rows)) {
                state.reservationProducts = data.rows
                .map(row => {
                    row.image = null;
                    
                    if (typeof row.images === 'string') {
                        const [ image ] = row.images.split(',');

                        row.image = image;
                        row.imageCropped = `${ image }=w640-h360-p`
                    }

                    return row;
                })
                .sort((a, b) => {
                    return a.name > b.name ? 1 : -1;
                })
            }

            return state.reservationProducts
        },
    }
}