<template>
<div class="wise-footer">
    <div class="wise-footer_lang">
        <WiseCountrySelector 
            v-model="lang"
            class="small" 
            :options="['FI','GB','SE']" 
            @change="onCountrySelect" />
    </div>
    <div class="wise-footer_darkMode">
        <ion-toggle
            aria-label="dark mode" 
            :checked="darkMode"
            @ionChange="($event) => $store.commit('common/screenMode', $event.target.checked ? 'dark' : 'light')">
        </ion-toggle>
    </div>
    <div class="wise-footer_logo">
        <img class="footer-image hidden-dark" src="@/assets/wisenetwork_logo.png" />
        <img class="footer-image hidden-light" src="@/assets/wisenetwork_logo_dark.png" />
    </div>
    <div class="wise-footer_version">
        <span v-if="showVersion" class="footer-version">{{ version }}</span>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Preferences } from '@capacitor/preferences';
import moment from 'moment';
import settings from '@/WiseSettings.js';
import { setI18nLanguage, loadLocaleMessages, SUPPORT_LOCALES } from '@/i18n.js';
import i18nInitiated from "@/main"

export default {
    props: {
        showVersion: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    data: () => ({
        version: settings.version,
        lang: 'FI',
    }),
    computed: {
        ...mapGetters({
            darkMode: 'common/darkMode',
        }),
    },
    methods: {
        getCountryCode(key, reverse = false) {
            const dictionary = {
                FI: 'fi',
                SE: 'sv',
                GB: 'en',
                DE: 'de',
            }

            if (reverse) {
                const keys = Object.keys(dictionary)
                const values = Object.values(dictionary)

                return keys[values.indexOf(key)]
            }

            return dictionary[key]
        },
        onCountrySelect(event) {            
            const language = this.getCountryCode(event.country)
            // set system language!
            i18nInitiated.then(async(i18n) => {
                await Preferences.set({ key: `selectedLocale-${ settings.appId }`, value: language });

                if (!i18n?.global?.availableLocales.includes(language)) {
                    await loadLocaleMessages(i18n, language)
                }

                setI18nLanguage(i18n, language)
                moment.locale(language);
                this.$store.commit('common/mutateUserLocale', SUPPORT_LOCALES[language].locale)
            })    
        },
    },
    async beforeCreate() {
        const { value: lang } = await Preferences.get({ key: `selectedLocale-${ settings.appId }` });
        this.lang = this.getCountryCode(lang, true);
    },
}
</script>

<style scoped>
.wise-footer {
    margin: var(--wn-space-6) 0;
    display: flex;
    gap: var(--wn-space-5);
    align-items: center;
    justify-content: space-between;
}

.footer-image {
    width: 100px;
}

.wise-footer_darkMode {
    margin-right: auto;
    display: flex;
}

.footer-version {
    font-size: 12px;
    color: var(--wn-txt-clr-tertiary);
}
</style>