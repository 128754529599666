// WiseReservation vuex modules
import res_common from '@/WiseEcom/EcomReservation/store/commonStore.js'
import res_calendar from '@/WiseEcom/EcomReservation/store/calendarStore.js'
import res_ecom from '@/WiseEcom/EcomReservation/store/ecomStore.js'
import res_memberships from '@/WiseEcom/EcomReservation/store/membershipsStore.js'
import res_reservations from '@/WiseEcom/EcomReservation/store/reservationStore.js'
import res_golf from '@/WiseEcom/EcomReservation/store/golfStore.js'

import { setLocaleFunction } from '../services/WiseLocale.js'

// EcomReservations styles
import '@/WiseEcom/EcomReservation/EcomReservation.css';

const components = {
	
}

export default {
	install(app, { i18n }) {
		Object.keys(components).forEach((name) => {
			const component = components[name];
			app.component(name, component);
		});

		if (typeof app.config.globalProperties.$store !== 'undefined') {
            app.config.globalProperties.$store.registerModule('res_common', res_common)
			app.config.globalProperties.$store.registerModule('res_calendar', res_calendar)
            app.config.globalProperties.$store.registerModule('res_ecom', res_ecom)
            app.config.globalProperties.$store.registerModule('res_memberships', res_memberships)
            app.config.globalProperties.$store.registerModule('res_reservations', res_reservations)
			app.config.globalProperties.$store.registerModule('res_golf', res_golf)
		}

		if (i18n) {
			setLocaleFunction(i18n.global.t)
		}
	}
}