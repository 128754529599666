<template>
<ion-page>
    <ion-content :fullscreen="true" class="">
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button color="primary" expand="block" size="large" @click="$router.push('/tabs/front')">GYM</ion-button>
                </ion-col>
                <ion-col>
                    <ion-button color="primary" expand="block" size="large" @click="$router.push('/golf/front')">GOLF</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-content>
</ion-page>
</template>