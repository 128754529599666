// https://stackoverflow.com/a/51567564
export function wc_hex_is_light(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
}

export function playerMainMembership(player, adminMode) {
    if (!adminMode) return '';
    if (!player.accessControlCategories) return '';

    const [ mainCategory ] = player.accessControlCategories.split('|');

    if (!mainCategory) return '';

    return mainCategory;
}

export function playerMembershipAbbr(player, adminMode) {
    const mainCategory = playerMainMembership(player, adminMode)

    if (!mainCategory) return '';

    return mainCategory
        .replace(/[^a-zA-Z\s-]/g, '')
        .split(/[-\s]/)
        .filter(Boolean)
        .map(word => word[0].toUpperCase())
        .join('')
}

export function playerMembershipColor(player, adminMode) {
    if (!adminMode) return '';
    if (!player.accessControlColor) return '';

    const [ mainCategory ] = player.accessControlColor.split('|');

    if (!mainCategory) return '';

    const bgColor = (mainCategory[0] !== '#' ? '#':'') + mainCategory;
    const color = wc_hex_is_light(bgColor) ? 'rgba(51,51,51,0.8)' : 'rgba(245,245,245,0.8)';

    return [`background-color: ${ bgColor }`,`color: ${ color }`]; 
}