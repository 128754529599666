<template>
	<ion-page>
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>
			<ion-tab-bar slot="bottom">
				<ion-tab-button tab="login" href="/gym/front">
					<i class="wn-icon home" />
					<!-- <ion-label>{{ loggedIn ? $t("tabs.frontPage") : $t('tabs.login')}}</ion-label> -->
				</ion-tab-button>

				<ion-tab-button v-if="loggedIn === true" tab="fitness" href="/gym/fitness">
					<i class="wn-icon calendar"></i>
					<!-- <ion-label>{{$t('tabs.calendar')}}</ion-label> -->
				</ion-tab-button>

				<!-- <ion-tab-button v-if="loggedIn === true" tab="benefits" href="/tabs/benefits"> 
					<i class="wn-icon basket"></i>
					<ion-label>{{ $t('benefits.title') }}</ion-label>
				</ion-tab-button> -->

				<ion-tab-button v-if="loggedIn === true" tab="today" href="/gym/today">
					<i class="wn-icon notification"></i>
					<!-- <ion-label>{{$t('tabs.news')}}</ion-label> -->
				</ion-tab-button>

				<ion-tab-button v-if="loggedIn === true" tab="account" href="/gym/account">
					<i class="wn-icon account"></i>
					<!-- <ion-label>{{$t('tabs.account')}}</ion-label> -->
				</ion-tab-button>

				<!-- <ion-tab-button @click="openSupportModal()">
					<i class="wn-icon question-line"></i>
					<ion-label>{{$t('tabs.openSupport')}}</ion-label>
				</ion-tab-button> -->
			</ion-tab-bar>
		</ion-tabs>
	</ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonRouterOutlet } from "@ionic/vue";
import { notifications, create, card, helpCircle, barbellOutline, personCircleOutline, basket } from "ionicons/icons";
//import SupportModal from "@/WiseCore/components/WiseSupportModal.vue";
import { mapState } from "vuex";

export default {
	name: "GymTabs",
	components: { IonTabs, IonTabBar, IonTabButton, IonRouterOutlet },
	data() {
		return {
			notifications,
			create,
			card,
			helpCircle,
			barbellOutline,
			personCircleOutline,
			basket
		}
	},
	computed: {
		...mapState({loggedIn: state => state.user.loggedIn}),
	},
	methods: {

	/*	
    async openSupportModal() {
      const modal = await modalController
        .create({
          component: SupportModal,
          cssClass: 'wise-support-modal',
					// mode: 'md',
          componentProps: {
            title: this.$t("customerFeedback")
          },
        })
      return modal.present();
    },

	*/
	},
};
</script>

<style scoped>
ion-tab-button.hide {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
</style>