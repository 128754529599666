import { ref } from 'vue'

const showTabs = ref(true)
const setShowTabs = (value) => showTabs.value = value

export default function useWiseTabs() {
    return {
        showTabs,
        setShowTabs,
    }
}