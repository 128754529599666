import moment from 'moment';

export default {
	methods: {
		// Check if event start time is in confirmable minutes and current user is marked as attending, but have not yet been confirmed.
		checkIsEventConfirmableByUser(settings, personId, event) {
			if(this.isEventConfirmable(settings, event) === true && event?.eventAttendees?.length > 0) {
				if(this.userEventStatus(personId, event) === 1) {
					return true
				}
			}
			return false
		},

		// Get user status for event
		userEventStatus(personId, event) {
			// 0 = Not in attendance
			// 1 = In attendance but not confirmed
			// 2 = In attendance and confirmed in
			// 3 = In queue waiting for open slot
			const isPersonInAttendance = event?.eventAttendees?.findIndex((attendee) => attendee.personId === personId && attendee.type === 1) > -1;
			const isPersonAttendeeConfirmed = event?.eventAttendees?.findIndex((attendee) => attendee.personId === personId && attendee.type === 1 && attendee.registered) > -1;
			const isPersonInQueue = event?.eventAttendees?.findIndex((attendee) => attendee.personId === personId && attendee.type === 3) > -1;
			return (isPersonInAttendance ? (isPersonAttendeeConfirmed ? 2 : 1) : (isPersonInQueue ? 3 : 0));
		},

		// Return true if enrollment currently open, false if enrollment already over, datetime string if enrollment opens in the future.
		isEventEnrollable(settings, fitnessData, event) {
			const untilStartMinutes = moment(event.activityStartTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes');
			const untilEndMinutes = moment(event.activityEndTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes');

			// For some categories we check the activityEndTime instead of start.
			const canEnrollUntilEnd = fitnessData?.categories !== false ? fitnessData?.categories?.find(category => category.eventCategoryId === this.eventData.eventCategoryId)?.allowEnrollUntilEnd : false

			// Has eventalready started, or already ended for those events where enrollment until end is allowed
			if ((canEnrollUntilEnd === false && untilStartMinutes < 0) || (canEnrollUntilEnd === true && untilEndMinutes < 0)) return false;
			if (canEnrollUntilEnd === false && untilStartMinutes <= settings.eventEnroll.stopBeforeMinutes) return false;

			// If enrollment not open yet, show date when it will open.
			if (untilStartMinutes > settings.eventEnroll.startBeforeMinutes) {
				return moment.parseZone(event.activityStartTime, 'DD.MM.YYYY HH:mm').subtract(settings.eventEnroll.startBeforeMinutes, 'minutes').format('DD.MM.YYYY HH:mm');
			}

			// Enrollment open
			return true;
		},

		// Check if user can cancel an enrolled or confirmed event
		isEventCancellable(settings, personId, event) {
			const untilStartMinutes = moment(event.activityStartTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes');
			const fitnessStatus = this.userEventStatus(personId, event)
			const allowEnrollConfirmCancelBeforeMinutes = typeof settings.allowEnrollConfirmCancelBeforeMinutes === 'undefined' ? true : settings.allowEnrollConfirmCancelBeforeMinutes;

			// allowEnrollConfirmCancelBeforeMinutes of false means cancel not allowed. true means cancel allowed. Number means allowed until less than that many minutes to start.
			// This applies to enroll and confirmed (if allowConfirmCancel is true) both. Queue cancelling is not affected by this.
			// If user is enrolled or confirmed and Allow cancel is false, Or untilStartMinutes is under cancel before minutes
			if ([1, 2].includes(fitnessStatus) === true && (allowEnrollConfirmCancelBeforeMinutes === false || (allowEnrollConfirmCancelBeforeMinutes !== true && untilStartMinutes <= settings.allowEnrollConfirmCancelBeforeMinutes))) {
				if(untilStartMinutes <= settings.allowEnrollConfirmCancelBeforeMinutes) {
					console.warn("untilStartMinutes is less than in settings allowEnrollConfirmCancelBeforeMinutes")
				}
				return false;
			}

			// If confirmed, can only cancel if allowConfirmCancel is set
			if (fitnessStatus === 2 && settings.eventConfirm.allowConfirmCancel !== true) {
				if(settings.eventConfirm.allowConfirmCancel === false) {
					console.warn("In settings allowConfirmCancel:", false)
				}
				return false;
			}

			// Can cancel
			return true;
			},

		// Users can begin to confirm event attendance startBeforeMinutes before event begins.
		isEventConfirmable(settings, event) {
			const untilStartMinutes = moment(event.activityStartTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes')
			const untilEndMinutes = moment(event.activityEndTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes')
			return untilStartMinutes <= settings.eventConfirm.startBeforeMinutes && untilEndMinutes > 0;
		}
	},
};