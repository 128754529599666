<template>
<ion-page>
    <ion-header>
        <ion-toolbar>
            <ion-title>
                <slot name="modal-title"></slot>
            </ion-title>
            <ion-buttons slot="end">
                <ion-button @click="closeModal">
                    <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <slot :closeModal="closeModal"></slot>
    </ion-content>
</ion-page>
</template>

<script>
import { closeOutline } from 'ionicons/icons';
import { modalController } from '@ionic/vue';

export default {
    data: () => ({
        closeOutline,
    }),
    methods: {
        closeModal() {
            modalController.dismiss();
            this.$emit('close');
        }
    }
}
</script>