<template>

    <ion-list>
        <ion-item>
            <ion-select value="fin">
                <ion-select-option value="fin"></ion-select-option>
                <ion-select-option value="eng"></ion-select-option>
                <ion-select-option value="sve"></ion-select-option>
            </ion-select>
        </ion-item>
    </ion-list>

</template>

<script>
    import {mapMutations} from "vuex";
    export default {
        name: "WiseLocaleSelector",
        data() {
            return {

            }
        },
        methods: {
            ...mapMutations({mutateUserLocale: "common/mutateUserLocale"}),
            
        }
    }
</script>