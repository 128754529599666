<template>
	<template v-if="size === 'small'">
		<!-- SIZE SMALL -->
		<div class="wise-error ion-text-center small">
			<ion-text color="warning">
				<i class="wn-icon error"></i>
			</ion-text>
			<ion-text color="dark">
				<h6 class="ion-no-margin">{{ errorMessage }}</h6>
				<p class="ion-no-margin">{{ $t("errors.loadingArticlesText") }}</p>
			</ion-text>
		</div>
	</template>
	<template v-else>
		<!-- SIZE LARGE  -->
		<div class="wise-error ion-text-center">
			<ion-text color="warning">
				<i class="wn-icon error"></i>
			</ion-text>
			<ion-text color="dark">
				<h3 class="ion-no-margin">{{ errorMessage }}</h3>
				<p class="ion-no-margin">{{ $t("errors.loadingArticlesText") }}</p>
			</ion-text>
		</div>
	</template>
</template>

<script>
import { alertCircleOutline } from "ionicons/icons";
export default {
	name: "WiseError",
	props: {
		errorMessage: String,
		size: {
			default: "large",
		},
	},
	data() {
		return {
			alertCircleOutline,
		};
	},
};
</script>
