// mimic jquery $.param function
export function param(obj) {
    return Object.keys(obj)
        .map((key) => {
            const value = obj[key];

            if (Array.isArray(value)) {
                return value
                    .map(row => `${ key }[]=${ row }`)
                    .join('&');
            } else if (typeof value === 'object' && value !== null) {
                return param(value);
            } else {
                return `${ key }=${ value }`;
            }
        })
        .join('&')
}