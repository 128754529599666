import axios from 'axios';
import moment from 'moment';
import locationAndBluetooth from '@/helpers/locationServicesAndBluetooth'
import commonGymFunctions from '@/helpers/commonGymFunctions'

// Used for to populate gym_common state on initial load and reset gym_common state on logout
const getGymCommonDefaultState = () => {
  return {
		fitnessData: null,
		loadingFitnessData: false,
		fitnessDataLoadedWithLocale: null,
		updateFitnessDataInterval: null, // Used when user logs out to clear interval
		selectedDate: new Date(),
		ownEvents: false,
		ownEventsObject: false,
		selectedCategory: -1,
		selectedFitnessLocation: null,
		appAuth:  null,
		customerTermsArticleId: null,
  }
}

export default {
	namespaced: true,
	state: getGymCommonDefaultState(),
	getters: {
		// Get user confirmable events, updates every time when fitnessdata is fetched
		userConfirmableEvents: (state, getters, rootState) => {
			console.log("gym_common: getters: userConfirmableEvents")
			const userConfirmableEvents = state.fitnessData?.events.filter((event) => commonGymFunctions.methods.checkIsEventConfirmableByUser(rootState.common.settings, rootState.user?.user?.personId, event))
			if(typeof(userConfirmableEvents) === "object" && userConfirmableEvents.length > 0) {
				return userConfirmableEvents
			}
			return []
		},

		// Check if location check is required, or is inside any location, or have successful location validation in settings.eventConfirm.ifDoorOpenedInLastMinutes
		// Updates everytme when user cordinates change or successfull location validation timestamp change
		canUserConfirm: (state, getters, rootState) => {
			console.log("gym_common: getters: canUserConfirm")
			// Check if location check is needed to confirm event
			if(rootState.common.settings.eventConfirm.requireLocationCheck !== true) {
				return true
			}

			// rootState.door.userCurrentCordinates needs to be here to getter watch this value change
			const userCurrentCordinates = rootState.door.userCurrentCordinates

			// Check if inside valid GPS area
			const isInsideAnyLocationResponse = isInsideAnyLocation(userCurrentCordinates, rootState)
			if(isInsideAnyLocationResponse === true) {
				return true
			}

			// Check if last successful door open is in settings.eventConfirm.ifDoorOpenedInLastMinutes
			if(rootState.door.successfulLocationValidation.timestamp !== null) {
				const successfullDoorOpenDateTime = moment.unix(rootState.door.successfulLocationValidation.timestamp).format('DD.MM.YYYY HH:mm')
				const minutesFromSuccessfulDoorOpen =  Math.abs(moment(successfullDoorOpenDateTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes'))
				return minutesFromSuccessfulDoorOpen <= rootState.common.settings.eventConfirm.ifDoorOpenedInLastMinutes
			}

			// If we are here user can not confirm event
			return false
		}
	},
	mutations: {
		mutateGymCommonStateToDefault(state) {
			Object.assign(state, getGymCommonDefaultState())
		},
		mutateFitnessData(state, fitnessData) {
			state.fitnessData = fitnessData;
		},
		mutateLoadingFitnessData(state, loadingFitnessData) {
			state.loadingFitnessData = loadingFitnessData;
		},
		mutatefitnessDataLoadedWithLocale(state, fitnessDataLoadedWithLocale) {
			state.fitnessDataLoadedWithLocale = fitnessDataLoadedWithLocale;
		},
		mutateSelectedDate(state, selectedDate) {
			state.selectedDate = selectedDate;
		},

		mutateSelectedCategory(state, selectedCategory) {
			state.selectedCategory = selectedCategory;
		},
		mutateSelectedFitnessLocation(state, selectedFitnessLocation) {
			state.selectedFitnessLocation = selectedFitnessLocation;
		},
		mutateHandleFitnessEvent(state, event) {
			const eventIndex = state.fitnessData.events.findIndex(eventItem => eventItem.activityId === event.activityId)
			state.fitnessData.events[eventIndex] = event
			// console.log(state.fitnessData)
		},
		mutateMarkAttendance(state, data) {
			const eventIndex = state.fitnessData.events.findIndex(eventItem => eventItem.activityId === data.activityId)
			const attendeeIndex = state.fitnessData.events[eventIndex].eventAttendees.findIndex(attendee => attendee.attendeeId === data.attendeeMark.attendeeId)
			state.fitnessData.events[eventIndex].eventAttendees[attendeeIndex].markedAttendance = data.attendeeMark.markedAttendance
		},
		mutateOwnEvents(state, ownEvents) {
			state.ownEvents = ownEvents;
		},
		mutateOwnEventsObject(state, ownEventsObject) {
			state.ownEventsObject = ownEventsObject;
		},
		mutateCanUserConfirm(state, canUserConfirm) {
			state.canUserConfirm = canUserConfirm;
		},
		mutateUpdateFitnessDataInterval(state, updateFitnessDataInterval) {
			state.updateFitnessDataInterval = updateFitnessDataInterval;
		}
	},
	actions: {
		async getFitnessData({ rootGetters, commit, rootState, dispatch }) {
			console.log("gym_common: getFitnessData()")

			// Only show loading on initial fetch, when updating fitness data no need to show loading
			if(rootState.gym_common.fitnessDataLoadedWithLocale === null) {
				commit('mutateLoadingFitnessData', true)
			}

			// Get fitness data, Filter events to categories, Init fitness data update interval
			try {
				// Porihalli url for testing
				// const fitnessData = await axios.get(`https://ajax.porihalli.com/?controller=ajax&fitnessdata=1&lang=${rootState.common.userLocale}&appauth=wNtVCbqZTCogoVpJ`)
				const fitnessData = await axios.get(`${rootGetters["common/activeHost"].ajaxUrl}?controller=ajax&fitnessdata=1&lang=${rootState.common.userLocale}&appauth=${rootGetters["common/activeHost"].appauth}`)

				// Initialize Fitnessdata update on first succesful fitnessdata fetch
				if(rootState.gym_common.fitnessDataLoadedWithLocale === null) {
					const updateFitnessDataInterval = setInterval( async() => {
						await dispatch("getFitnessData")
					}, rootState.common.settings.refreshEventDataMinutes * 60 * 1000);
					// Save updateFitnessDataInterval to state, this is used to clear interval when user logout.
					commit('mutateUpdateFitnessDataInterval', updateFitnessDataInterval)
				}

				commit('mutatefitnessDataLoadedWithLocale', rootState.common.userLocale)

				// If have categories
				if (fitnessData.data.categories !== false) {
					fitnessData.data.categories.forEach((category, index) => {
						fitnessData.data.categories[index].events = fitnessData.data.events.filter(event => event.eventCategoryId === category.eventCategoryId)
					})
				}

				commit('mutateFitnessData', fitnessData.data)
				commit('mutateLoadingFitnessData', false)
				commit('mutateSelectedCategory', fitnessData.data.categories[0])

				const ownEvents = fitnessData.data.events.filter(event => event.eventAttendees?.find(attendee => attendee?.personId === rootState.user?.user?.personId))
				//Store to state.gym_common.ownEvents
				commit('mutateOwnEventsObject', ownEvents)
			} catch (error) {
				// console.error(error)
				commit('mutateFitnessData', null)
				commit('mutateLoadingFitnessData', false)
				return Promise.reject(error)
			}
		},

		async handleFitnessEvent({ rootGetters, commit, rootState }, data) {
			const eventHandleData = {
				action: data.action,
				activityId: data.event.activityId,
				hash: data.event.hash,
				isMobile: true,
				isUserLocal: false,
				personId: rootState.user.user.personId,
				tagData: false
			}

			try {
				const { data } = await axios.post(`${rootGetters["common/activeHost"].ajaxUrl}?controller=ajax&handleevent=1&lang=${rootState.common.userLocale}&appauth=${rootGetters["common/activeHost"].appauth}`, eventHandleData)
				if (Object.keys(data)[0] === 'error') {
					return Promise.reject(data.error)
				}
				commit('mutateHandleFitnessEvent', data.event)

				return data
			} catch (error) {
				console.error(error)
				throw(error)
			}
		},

		async markFitnessAttendance({ rootGetters, commit, rootState }, attendee) {
			const attendeeMark = {
				attendeeId: attendee.attendeeId,
				markedAttendance: attendee.markedAttendance
			}

			try {
				const { data } = await axios.post(`${rootGetters["common/activeHost"].ajaxUrl}?controller=ajax&markattendance=1&lang=${rootState.common.userLocale}&appauth=${rootGetters["common/activeHost"].appauth}`, attendeeMark)
				if (Object.keys(data)[0] === 'error') {
					return Promise.reject(data.error)
				}
				commit('mutateMarkAttendance', { attendeeMark, activityId: attendee.activityId })

				return data
			} catch (error) {
				console.error(error)
				throw(error)
			}
		},
	}
}

// Check if user is inside any location
const isInsideAnyLocation = (userCurrentCordinates, rootState) => {
	let gpsInsideLocation = false
	if(userCurrentCordinates !== null) {
		// Check if inside any location, exit loop if inside location === true
		for (const location of rootState.door.doorAndLocationData.locations) {
			const containsLocationCheck = locationAndBluetooth.methods.containsLocationCheck(userCurrentCordinates,location.locationSettings.coordinates, rootState.common.settings.devLocation) // devLocation
			// console.log("containsLocationCheck", containsLocationCheck)
			if(containsLocationCheck.isInsideArea === true || (containsLocationCheck.isInsideDevArea === true && rootState.common.settings.validateLocationInsideDevLocation === true)) {
				gpsInsideLocation = true
				break
			}
		}
		return gpsInsideLocation
	}
	return gpsInsideLocation
}