import { _locale } from '../WiseEcom/services/WiseLocale';
import { wiseAlert } from '../WiseEcom/services/WiseAlert';
import store from '../store';
import router from './index.js';

function handleSessionRejection() {
    console.log(`%cAccess token unvalid, user need to re-login`, 'color: yellow; padding: 5px 0')
    wiseAlert({
        mode: 'ios',
        backdropDismiss: false,
        message: _locale('login.sessionExpired'),
        buttons: [
            {
                text: 'OK',
                handler: async () => {
                    // reset all states before trying to log back in
                    await store.dispatch('user/logOut')
                    router.replace('/');
                },
            },
        ]
    })
}

export async function defaultGuard(to, from, next) {
    // User is logged in, validate accessToken
    store.dispatch('user/validateAccessTokenFromStorage')
    .then((accessTokenValid) => {
        if (accessTokenValid === false) {
            handleSessionRejection()
        }
    })
    .catch(() => handleSessionRejection())
    // All good, user can access route
    next()
}