import moment from 'moment';
import GolfService from './GolfService.js'
import { validateResourceRule, testRuleToBlock } from './ReservationService.js'

const VIEW_MODES = {
    rows: 1,
    grid: 2
};
let defaultViewMode = VIEW_MODES.rows;
const playerClubProps = [
    'allPlayers',
    'playerAccessRights',
    'clubs',
];
const stateFactory = () => ({
    selectedViewMode: defaultViewMode,
    showTeeTimePrice: true,
    golfProducts: [],
    selectedPlayers: [], // ids of co-players
    allPlayers: [], // players from database
    playerAccessRights: [],
    accessRights: [],
    clubs: [],
    reservationPlayers: [], // separate from other player data; players linked to reservations
    // user spesific reservations and resources (golf carts, etc)
    golfReservations: [],
    golfAdditionalResources: [],
    defaultClubId: null,
    defaultHandicaps: {}, // default handicap limit rules per how many players in a tee time
    overridePlayerHandicap: {},
    selectedTeeTimes: [],
    multipurposeAccessRightQuantity: 0,
    reservationsUpdate: null, // timestamp to refresh outside calendar
})

/*
if (!!localStorage['golfResevationViewMode']) {
    try {
        defaultViewMode = JSON.parse(localStorage['golfResevationViewMode']);
    } catch(e) {}
}
*/

export default {
    namespaced: true,
    state: stateFactory(),
    getters: {
        VIEW_MODES: () => VIEW_MODES,
        GolfService: () => GolfService,
        selectedViewMode: (state) => state.selectedViewMode,
        golfProducts: (state) => state.golfProducts,
        userPlayer: (state, getters, rootState, rootGetters) => {
            const user = rootGetters['user/activeUser'];

            if (!user) {
                return { personId: null }
            }
            
            const [ player ] = state.allPlayers.filter(row => row.personId == user.personId)

            if (!player) {
                return { personId: null }
            }

            return player;
        },
        userMemberships: (state, getters) => {
            const userPlayer = getters['userPlayer'];

            return state.playerAccessRights.filter(row => row.personId === userPlayer.personId);
        },
        isPlayerSelected: (state) => (player) => state.selectedPlayers.indexOf(player.personId) > -1,
        selectedPlayers: (state) => {
            return state.selectedPlayers.map((personId) => {
                return state.allPlayers.filter(row => row.personId === personId)[0]
            })
        },
        allPlayers: (state) => state.allPlayers,
        playerAccessRights: (state) => state.playerAccessRights,
        accessRights: (state) => state.accessRights,
        multipurposeAccessRightQuantity: (state) => state.multipurposeAccessRightQuantity,
        getPlayerMultipurposeRights: (state, getters, rootState, rootGetters) => {
            const resourceRules = rootGetters['res_reservations/resourceRules'];
            const multipurpose = resourceRules.filter(row => 
                row.ruleName === 'pelioikeus' 
                && row.ruleValue 
                && row.ruleValue.multipurpose
            )
            const ruleIds = multipurpose.map(row => row.categoryId)
            const playerAccessRights = state.playerAccessRights;
            const reservationPlayers = state.reservationPlayers;

            return (personId) => {
                const accessRights = playerAccessRights.filter(
                    row => row.personId == personId && ruleIds.includes(row.categoryId)
                )

                if (accessRights.length === 0) return null;

                const [ accessRight ] = accessRights.map(row => {
                    const { categoryId, name } = row;
                    const reservations = reservationPlayers
                        .filter(row => row.usedCategoryId == categoryId)
                        
                    const [ rule ] = multipurpose.filter(row => row.categoryId == categoryId)
                    let totalQuantity = null;
                    let availableQuantity = null;
                    
                    if (rule.ruleValue && rule.ruleValue.groupDayLimit) {
                        totalQuantity = rule.ruleValue.groupDayLimit - reservations.length;
                        // - 1 is the userPlayer
                        availableQuantity = totalQuantity - 1;
                    }

                    return {
                        categoryId,
                        name,
                        totalQuantity,
                        availableQuantity,
                        usedQuantity: reservations.length,
                        reservations,
                    }
                })
                
                return accessRight
            }
        },
        players: (state, getters) => {
            const userPlayer = getters['userPlayer'];
            const allButUser = state.allPlayers.filter((row) => row.personId !== userPlayer.personId);
            const selectedPlayers = state.selectedPlayers;

            return allButUser.filter(row => selectedPlayers.indexOf(row.personId) === -1);
        },
        getPlayerPrice: (state, getters, rootState, rootGetters) => {
            const userPlayer = getters['userPlayer'];
            const getPlayerActiveStamps = getters['getPlayerActiveStamps'];
            const resourceRules = rootGetters['res_reservations/resourceRules'];
            const adminMode = rootGetters['res_common/adminMode'];
            const selectedPlayers = getters['selectedPlayers'].map(row => row.personId);
            const multipurposeAccessRightQuantity = getters['multipurposeAccessRightQuantity'];
            const getPlayerMultipurposeRights = getters['getPlayerMultipurposeRights'](userPlayer.personId);
            
            /**
             * @param {Number} personId
             * @param {Number} resourceId
             * @param {Moment} start
             * @param {Moment} end
             * @param {Number} basePrice
             */
            return (personId, resourceId, start, end, basePrice) => {
                const index = selectedPlayers.indexOf(personId);
                const accessRights = state.playerAccessRights.filter(row => row.personId === personId);
                const categoryIds = accessRights.map(row => row.categoryId);
                const rules = resourceRules
                .filter(row => testRuleToBlock(row, { start, end }))
                .filter(row =>
                    resourceId === row.resourceId
                    && row.ruleName === 'pelioikeus'
                    && categoryIds.includes(row.categoryId))
                let playerPrice = basePrice;
                
                if (rules.length > 0) {
                    playerPrice = rules.reduce((acc, curr) => {
                        if (typeof curr.ruleValue !== 'object') return acc;
                        if (getPlayerMultipurposeRights
                            && getPlayerMultipurposeRights.categoryId === curr.categoryId
                            && getPlayerMultipurposeRights.availableQuantity + 1 < 1) return acc;

                        const { price: newPrice, percent: newPercent } = curr.ruleValue;
                        let price = acc;

                        if (typeof newPrice === 'number' && newPrice < price) {
                            price = newPrice
                        }

                        if (typeof newPercent === 'number') {
                            const percent = newPercent / 100;
                            const percentPrice = price - (price * percent);

                            if (percentPrice < price) {
                                price = percentPrice;
                            }
                        }
                        
                        return price;
                    }, playerPrice)
                }

                // detect visitor player
                if (!adminMode && accessRights.length === 0) {
                    // get the user's right for a new price for the visitor player
                    const userPlayer = getters['userPlayer'];
                    const userAccessRights = state.playerAccessRights.filter(row => row.personId === userPlayer.personId);
                    const userCategoryIds = userAccessRights.map(row => row.categoryId);
                    const visitorPriceRules = resourceRules.filter(row =>
                        row.ruleName === 'vierailijaHinta'
                        && userCategoryIds.includes(row.categoryId));

                    if (visitorPriceRules.length > 0) {
                        playerPrice = visitorPriceRules.reduce((acc, curr) => {
                            if (typeof curr.ruleValue === 'number' 
                                && (curr.ruleValue < acc || acc === null)) {
                                return curr.ruleValue;
                            }

                            return acc;
                        }, playerPrice)
                    }
                }

                const activeStamps = getPlayerActiveStamps(personId, resourceId, start, end);

                if (activeStamps) {
                    playerPrice = 0.00;
                }

                if (!adminMode 
                    && getPlayerMultipurposeRights
                    && multipurposeAccessRightQuantity > 0 
                    && userPlayer.personId != personId
                    && index < multipurposeAccessRightQuantity) {
                    playerPrice = 0.00;
                }

                return playerPrice;
            }
        },
        allPlayerPrices: (state, getters, rootState, rootGetters) => {
            const getPlayerPrice = getters['getPlayerPrice'];
            const activeReservation = rootGetters['res_calendar/activeReservation'];
            const { start, end, resourceId } = activeReservation;

            return state.allPlayers.map(player => {
                let playerPrice = activeReservation.price;
                let priceChange = getPlayerPrice(player.personId, resourceId, start, end, playerPrice);

                if (priceChange !== null) {
                    playerPrice = priceChange;
                }

                return {
                    personId: player.personId,
                    price: playerPrice
                }
            })
        },
        getPlayerActiveStamps: (state, getters, rootState, rootGetters) => {
            const resourceRules = rootGetters['res_reservations/resourceRules'];
            const adminMode = rootGetters['res_common/adminMode'];
            const userPlayer = getters['userPlayer'];
            const quantity = 1; // we can assume that in GOLF it is always 1

            /**
             * @param {Integer} personId
             * @param {Moment} start
             * @param {Moment} end
             */
            return (personId, resourceId, start, end) => {
                if (!adminMode && userPlayer.personId === personId) return null;

                const accessRights = state.playerAccessRights.filter(row => row.personId === personId);
                const categoryIds = accessRights.map(row => row.categoryId);
                
                //handle kymppikortti
                if (accessRights.length > 0) {
                  const rules = resourceRules
                    .filter(row => testRuleToBlock(row, { start, end })
                        && row.ruleName === 'kymppikortti' 
                        && categoryIds.includes(row.categoryId)
                    )
                    .filter(row => {
                        const accessRight = accessRights.filter(accessRight => accessRight.categoryId === row.categoryId)[0];
                        const usageMultiplier = typeof row.ruleValue.usageMultiplier === 'number' ? row.ruleValue.usageMultiplier : 1;
                        
                        return accessRight.usableQuantity / usageMultiplier >= quantity;
                    })
                    
                    if(rules.length > 0) {
                        const { categoryId, ruleId } = rules[0]

                        return {
                          categoryId,
                          ruleId
                        }
                    }
                }

                return null;
            }
        },
        clubs: (state) => state.clubs,
        reservationPlayers: (state) => (filter) => state.reservationPlayers.filter(filter),
        golfReservations: (state) => state.golfReservations,
        golfAdditionalResources: (state) => state.golfAdditionalResources,
        defaultClubId: (state) => state.defaultClubId,
        defaultHandicaps: (state, getters, rootState, rootGetters) => {
            if (!Array.isArray(state.defaultHandicaps)) {
                return {};
            }
            return state.defaultHandicaps
                .filter(row => validateResourceRule(row, rootGetters['res_reservations/date']))
                .reduce((acc, curr) => {
                    acc[curr.resourceId] = curr.ruleValue.default;
                    return acc;
                }, {})
        },
        overridePlayerHandicap: (state) => (personId) => state.overridePlayerHandicap[personId],
        selectedTeeTimes: (state) => state.selectedTeeTimes.sort((a, b) => new Date(a.start) > new Date(b.start) ? 1 : -1),
        showStickyNotes(state) {
            if (state.selectedTeeTimes.length > 0) return true;

            return false;
        },
        showTeeTimePrice: (state) => state.showTeeTimePrice,
        reservationsUpdate: (state) => state.reservationsUpdate,
    },
    mutations: {
        reset(state) {
            const defaults = stateFactory();

            Object.keys(defaults).forEach(key => {
                if (playerClubProps.includes(key)) {
                    return;
                }

                const value = defaults[key];

                state[key] = value;
            })
        },
        resetPlayersClubs(state) {
            const defaults = stateFactory();

            Object.keys(defaults).forEach(key => {
                if (!playerClubProps.includes(key)) {
                    return;
                }

                const value = defaults[key];

                state[key] = value;
            })
        },
        toggleViewMode(state) {
            if (state.selectedViewMode === VIEW_MODES.rows) {
                state.selectedViewMode = VIEW_MODES.grid;
            } else {
                state.selectedViewMode = VIEW_MODES.rows;
            }

            localStorage['golfResevationViewMode'] = JSON.stringify(state.selectedViewMode);
        },
        setDefaultClubId(state, clubId) {
            state.defaultClubId = clubId;
        },
        setGolfProducts(state, rows) {
            state.golfProducts = rows;
        },
        setDefaultHandicaps(state, rows) {
            state.defaultHandicaps = rows;
        },
        setOverridePlayerHandicap(state, { personId, handicap }) {
            state.overridePlayerHandicap[personId] = handicap;
        },
        setReservationPlayers(state, players) {
            state.reservationPlayers = players;
        },
        setSelectedPlayers(state, players) {
            state.selectedPlayers = players;
        },
        setShowTeeTimePrice(state, value) {
            state.showTeeTimePrice = value;
        },
        selectPlayer(state, personId) {
            if (state.selectedPlayers.indexOf(personId) === -1) {
                state.selectedPlayers.push(personId);
            }
        },
        deselectPlayer(state, personId) {
            const index = state.selectedPlayers.indexOf(personId);

            state.selectedPlayers.splice(index, 1);
        },
        addPlayers(state, players) {
            const personIds = state.allPlayers.map(row => row.personId);
            const newPlayers = players.filter(row => personIds.indexOf(row.personId) === -1);

            state.allPlayers = [
                ...state.allPlayers,
                ...newPlayers,
            ];
        },
        addPlayerAccessRights(state, rows) {
            const ids = state.playerAccessRights.map(row => `${ row.personId }-${ row.categoryId }`);
            const newRows = rows.filter(row => ids.indexOf(`${ row.personId }-${ row.categoryId }`) === -1);

            state.playerAccessRights = [
                ...state.playerAccessRights,
                ...newRows,
            ];
        },
        addAccessRights(state, rows) {
            const ids = state.accessRights.map(row => row.categoryId);
            const newRows = rows.filter(row => ids.indexOf(row.categoryId) === -1);

            state.accessRights = [
                ...state.accessRights,
                ...newRows,
            ];
        },
        addClubs(state, clubs) {
            const clubIds = state.clubs.map(row => row.clubId);
            const newClubs = clubs.filter(row => clubIds.indexOf(row.clubId) === -1);

            state.clubs = [
                ...state.clubs,
                ...newClubs,
            ];
        },
        setGolfReservations(state, rows) {
            state.golfReservations = rows;
        },
        setGolfAdditionalResources(state, rows) {
            state.golfAdditionalResources = rows;
        },
        setSelectedTeeTimes(state, rows) {
            state.selectedTeeTimes = rows;
        },
        setMultipurposeAccessRightQuantity(state, value) {
            state.multipurposeAccessRightQuantity = value;
        },
        setReservationsUpdate(state, value) {
            state.reservationsUpdate = value;
        },
    },
    actions: {
        setGolfServiceSettings(context, settings) {
            GolfService.setSettings(settings)

            return Promise.resolve();
        },
        getPlayerInformation({ state, commit }, query = {}) {
            if (query.reset === true) {
                state.allPlayers = [];
                delete query.reset;
            }

            if (Object.keys(query).length === 0) {
                commit('resetPlayersClubs')
            }

            return GolfService.getPlayers(query)
                .then((response) => {
                    const { data } = response;

                    if (Array.isArray(data.rows)) {
                        commit('addPlayers', data.rows)
                    }

                    if (Array.isArray(data.accessRights)) {
                        commit('addPlayerAccessRights', data.accessRights)
                    }

                    return response;
                });
        },
        getClubInformation({ state, commit }, query = {}) {
            if (query.reset === true) {
                state.clubs = [];
                delete query.reset;
            }

            return GolfService.getClubs(query)
                .then((response) => {
                    const { data } = response;

                    if (Array.isArray(data.rows)) {
                        commit('addClubs', data.rows)
                    }

                    return response;
                });
        },
        saveTeetimePlayers({ commit, state, rootGetters }, { added, removed }) {
            const adminMode = rootGetters['res_common/adminMode'];
            const activeReservation = rootGetters['res_calendar/activeReservation']

            added.forEach(personId => {
                commit('selectPlayer', personId);
            })
            removed.forEach(personId => {
                commit('deselectPlayer', personId);
            })

            activeReservation.setOrderQuantity(
                state.selectedPlayers.length + (adminMode ? 0 : 1),
                true
            )
        },
        saveVisitorPlayer({ commit }, data) {
            return GolfService.saveVisitorPlayer(data)
                .then(response => {
                    const { data } = response;

                    if (data.success) {
                        const { player } = data
                        commit('addPlayers', [ player ]);
                        commit('selectPlayer', player.personId);
                    }

                    return response;
                })
        },
        getUserGolfReservations({ commit, rootGetters }, params = {}) {
            const EcomService = rootGetters['res_ecom/EcomService'];

            return EcomService.getUserGolfReservations(params)
                .then((response) => {
                    const { data } = response;

                    if (Array.isArray(data.rows)) {
                        commit('setGolfReservations', data.rows);
                    }
                    // this data is user golf carts only 
                    // so we can't just override the additional resources globally
                    if (Array.isArray(data.reservationsAdditionalResources)) {
                        data.reservationsAdditionalResources = data.reservationsAdditionalResources.map(row => {
                            row.resourceCategory = row.resourceCategory || '';
        
                            if (row.resourceCategory.includes('-')) {
                                // eslint-disable-next-line no-unused-vars
                                const [ type, number ] = row.resourceCategory.split('-')
                                
                                row.resourceNumber = number;
                            }
        
                            row.inFuture = moment(row.dateTimeStart) > moment()
                            
                            return row;
                        });
                        commit(
                            'setGolfAdditionalResources', 
                            data.reservationsAdditionalResources,
                        )
                    }

                    return data;
                })
        },
        saveGolfPlayerDetails({ state, rootGetters/*, dispatch*/ }, settings) {
            const user = rootGetters['user/activeUser'];
            const personId = user.personId;
            const player = state.allPlayers.filter(row => row.personId === personId)[0];
            
            return GolfService.saveGolfPlayerDetails(settings)
            .then(response => {
                const { data } = response;

                if (data.success) {
                    Object.keys(settings)
                    .forEach(key => player[key] = settings[key])
                    //dispatch('getPlayerInformation')
                }
                
                return response;
            })
        },
    }
}
