<template>
<wn-drop-down :class="{ 'country-selector_show-phone': showPhoneCode }">
    <template #button>
        <button class="country-selector_button">
            <span class="flag" 
                :class="[ activeCountry.country.toLowerCase() ]" 
                :aria-label="activeCountry.name">
            </span>
            <span v-if="showPhoneCode">
                {{ activeCountry.code }}
            </span>
            <i class="wn-icon caret-down"></i>
        </button>
    </template>
    <template #options>
        <ul class="country-selector_options">
            <li v-for="row in countries" 
                :key="row.country"
                @click="selectCountry(row.country)">
                <span class="country-selector_country">{{ showPhoneCode ? row.country : row.lang }}</span>
                <span class="flag" 
                    :class="[ row.country.toLowerCase() ]" 
                    :aria-label="row.name">
                </span>
                <span v-if="showPhoneCode">
                    {{ row.code }}
                </span>
            </li>
        </ul>
    </template>
</wn-drop-down>
</template>

<script>
import { countryCodes } from '../countryCodes';

export default {
    emits: ['update:modelValue', 'change'],
    props: {
        modelValue: {
            type: String,
            default: 'FI',
        },
        showPhoneCode: {
            type: Boolean,
            required: false,
            default: false,
        },
        options: {
            type: Array,
            required: false,
            default: () => []
        },
    },
    data: () => ({
    }),
    computed: {
        activeCountry() {            
            const [ country ] = countryCodes.filter(row => row.country === this.modelValue);
            
            return country;
        },
        countries() {
            if (this.options.length > 0) {
                return countryCodes.filter(row => this.options.includes(row.country));
            }
            return countryCodes;
        },
    },
    methods: {
        getCountry() {
            return this.activeCountry;
        },
        selectCountry(country) {
            this.$emit('update:modelValue', country);
            this.$nextTick(() => this.$emit('change', this.activeCountry))
        },
    },
}
</script>

<style scoped>
.country-selector_show-phone .wn-dropdown__base li {
    justify-content: flex-start;
}

.country-selector_show-phone .country-selector_options {
    min-width: 150px;
}
.country-selector_options {
    min-width: 65px;
}
.country-selector_country {
    width: 25px;
}
.country-selector_button {
    padding: 5px 7px;
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
}
.flag {
    display: block;
}
</style>