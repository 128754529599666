<template>
	<div>
		<DatePicker :first-day-of-week="2" :min-date='new Date()' is-expanded v-model="calendarDate" :locale="$i18n.locale" />
	</div>
</template>

<script>

import { mapState, mapMutations } from "vuex"
import { DatePicker } from 'v-calendar'
import { modalController } from "@ionic/vue";
import 'v-calendar/dist/style.css';

export default {
	name: "GymCalendarModal",
	computed: {
		...mapState({
			selectedDate: state => state.gym_common.selectedDate,
		}),
		calendarDate: {
			get () {
				return this.selectedDate
			},
			set (value) {
				this.mutateSelectedDate(value)
				this.mutateOwnEvents(false)
				this.closeModal()
			}
		}
	},
	methods: {
		...mapMutations({
			mutateSelectedDate: "gym_common/mutateSelectedDate",
			mutateOwnEvents: "gym_common/mutateOwnEvents"
		}),
		closeModal() {
			modalController.dismiss();
		},
	}, components: {
		DatePicker
	}
};
</script>

<style>
	.gym-calendar-modal::part(content) {
		background: transparent;
		width: 90% !important;
		height: 48% !important
	}
	.vc-title {
		text-transform: capitalize !important;
	}
	.vc-nav-item {
		width: 70px !important;
		text-transform: capitalize !important;
	}
</style>