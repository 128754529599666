<template>
	<div class="height-100">
				<div class="wise-picker-wrapper">
					<h3 class="ion-no-margin" :class="{ 'ion-margin-bottom': !doors || (doors && doorAndLocationData.locations.length < 2) }">{{ pickerTitle }}</h3>
					<h6 v-if="doors && doorAndLocationData.locations.length > 1" class="primaryButton ion-margin-bottom">{{ $t("wiseGymcard.doorOpen.location") }}: {{ getLocationNameByLocationId(doorDataSelectedLocationId) }}</h6>
					<!-- DOORS PICKER -->
					<template v-if="doors !== false">
						<ion-button v-for="door in doors" :key="door.readerId" class="primaryButton" @click="closeModal(door.readerId);">
							{{ door.readerLang[userLocale].doorName }}
						</ion-button>
					</template>
					<!-- LOCATIONS PICKER -->
					<template v-if="doors === false">
						<ion-button v-for="location in doorAndLocationData.locations" :key="location.locationId" class="primaryButton" @click="mutateDoorDataSelectedLocationId(location.locationId); mutateDoorOpenFailures(null); closeModal();">
							{{ location.locationName }}
						</ion-button>
					</template>
					<ion-button class="primaryButton" @click="closeModal(false, true)">{{ $t("cancel")}}</ion-button>
				</div>


	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { modalController } from "@ionic/vue";
import locationAndBluetooth from '@/helpers/locationServicesAndBluetooth'

export default {
	name: "WiseDoorAndLocationPicker",
	mixins: [locationAndBluetooth],
	props: {
		doors: {
			default: false
		},
		isCheckinOpen: {
			default: false
		}
	},
	computed: {
		...mapState({
			doorAndLocationData: state => state.door.doorAndLocationData,
			doorDataSelectedLocationId: state => state.door.doorDataSelectedLocationId,
			userLocale: state => state.common.userLocale,
		}),

		pickerTitle() {
			if(this.doors === false) {
				return this.$t("wiseGymcard.doorOpen.chooseLocation")
			}
			return this.isCheckinOpen === false ? this.$t("wiseGymcard.doorOpen.chooseDoorToOpen") : "Check-In"
		}

	},
	methods: {
		...mapMutations({
			mutateDoorDataSelectedLocationId: "door/mutateDoorDataSelectedLocationId",
			mutateDoorOpenFailures: "door/mutateDoorOpenFailures"
		}),
		// If picker is showning doors to choose, picker returns readerId when door is choosed
		closeModal(readerId = false, cancel = false) {
			if(readerId !== false) {
				modalController.dismiss({readerId});
			}
			else if(cancel === true) {
				modalController.dismiss({cancel});
			}
			else {
				modalController.dismiss();
			}
		},
	},
};
</script>

<style scoped>
	ion-content {
		--background: transparent /* Modal background */
	}
	h6 {
		font-weight: normal;
		margin-top: 5px;
	}
	.wise-picker-wrapper {
		background: #fff;
    width: 80%;
    padding: 20px;
    color: #000;
	}
</style>