<template>

		<!-- If no events -->
		<div class="no-events center " v-if="categories === true && filterEventsByDate(events).length < 1 || categories === false && filterEventsByDate(events).length < 1">
			<div>
				<h3 class="front-subheader">{{ ownEvents === true ? $t("wiseFitness.noOwnEvents") : $t("wiseFitness.noEvents") }}</h3>
			</div>
		</div>

		<ion-item class="calendar-event" lines="none" v-for="event in events ? filterEventsByDate(events) : filterEventsByDate(events)" :key="event.activityId" @click="() => $router.push(`fitness/event/${event.activityId}`)"
			:class="{ attending: isAttendingToEvent(event),canceled: event.totalCapacity === -1, queued: event.currentQueuePosition > 0, 'registered': isRegisteredToEvent(event) }">
			<ion-grid>
				<ion-row class="ion-align-items-center">
					<ion-col size="10" class="calendar-header"> {{ event.name }}</ion-col>
					<ion-col v-if="!(event.totalCapacity < 1)" class="calendar-capacity ion-text-center">{{ event.totalAttendeeQuantity }} <span v-if="event.totalQueueQuantity > 0">({{ event.totalQueueQuantity }})</span> / {{ event.totalCapacity }}</ion-col>
				</ion-row>
				<ion-row class="calendar-subtext">
					<ion-col size="10">{{event.activityDisplayPersons}}, {{ event.venue }} <br>{{ $filters.upperCaseFirst($filters.eventFormatDate(event)) }}</ion-col>

					<ion-col class="ion-justify-content-center">
						<ion-button color="primary"
						@click="handleEvent($event, getUserEnrollButton(event).action, event)"
						v-if="getUserEnrollButton(event).action !== 'hide'" :disabled="getUserEnrollButton(event).action === 'disabled'"
							:class="{ attendingButton: isAttendingToEvent(event), canceledButton: event.totalCapacity === -1 , queuedButton: event.currentQueuePosition > 0, 'registeredButton': isRegisteredToEvent(event) }" class="circle">
							<i slot="icon-only" class="s-500 wn-icon" :class="{'check': isRegisteredToEvent(event) || isAttendingToEvent(event), 'user-add': !isRegisteredToEvent(event) && !isAttendingToEvent(event), group: event.currentQueuePosition > 0, closed: event.totalCapacity === -1 }"></i>
						</ion-button>
					</ion-col>


				</ion-row>	
			</ion-grid>
		</ion-item>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import { albumsOutline, personAddOutline, checkmarkCircleOutline } from "ionicons/icons";
import moment from 'moment';
import commonGymFunctions from '@/helpers/commonGymFunctions'

export default {
	name: "GymCategoryList",
	mixins: [commonGymFunctions],
	props: {
		events: Object,
		categories: Boolean
	},
	data() {
		return {
			albumsOutline,
			personAddOutline,
			checkmarkCircleOutline
		}
	},
	computed: {
		...mapGetters({
			activeHost: "common/activeHost",
			canUserConfirm: "gym_common/canUserConfirm"
		}),
		...mapState({
			selectedDate: state => state.gym_common.selectedDate,
			ownEvents: state => state.gym_common.ownEvents,
			user: state => state.user.user,
			settings: (state) => state.common.settings,
			fitnessData: (state) => state.gym_common.fitnessData,
			
		}),
		
	},
	methods: {

		...mapActions({
			getFitnessData: "gym_common/getFitnessData",
			handleFitnessEvent: "gym_common/handleFitnessEvent",
			markFitnessAttendance: "gym_common/markFitnessAttendance"
			}),

		// Handles event "attend, cancel, joinqueue"
		async handleEvent($event, action, event) {
			$event.stopImmediatePropagation();
			console.log("Error:", action, event)
			console.log("canUserConfirm:", this.canUserConfirm)
			console.log("isEventConfirmable:",this.isEventConfirmable(this.settings, event))
			try {
				const response = await this.handleFitnessEvent({action, event})
				this.showAlert(this.$t("wiseFitness.event.successOperation"), response.success)
			} catch (error) {
				console.error(error)
				this.showAlert(this.$t("error"), error)
			}
		},

		getUserEnrollButton(event) {

			// No right to enroll
			// Enrollment starts on
			// Enrollment is disabled
			// Event is full
			// Queue at position
			// Join queue
			// Enroll to event
			// Enroll and confirm

			let button = {
				action: "hide",
				text: "",
				class: "",
				icon: ""
			};

			if(this.settings?.fitnessEnrollmentEnabled !== true) {
				// Hide enroll button
				return button
			}

			// If user is not admin and has not event enroll permission
			if(this.user?.isAdmin?.events === false && event.hasEventEnrollPermission === false) {
				// No right to enroll
				button.action = "disabled"
				button.text = this.$t('wiseFitness.noRightToEnroll')
				return button
			}

			// EVENT ENROLL IS POSSIBLE IF EVENT IS IN FUTURE OR EVENT ENDTIME IS IN FUTURE AND ENROLL UNTIL END IS ALLOWED
			const activityStartTimeIsAfterCurrentTime = this.$filters.checkIsAfterNow(event?.activityStartTime)
			const activityEndTimeIsAfterCurrentTime = this.$filters.checkIsAfterNow(event?.activityEndTime)
			const canEnrollUntilEnd = this.fitnessData?.categories !== false ? this.fitnessData?.categories?.find(category => category.eventCategoryId === event.eventCategoryId)?.allowEnrollUntilEnd : false

			// If event start time is not in future, or (event end time is not in future and canEnrollUntilEnd is false)
			if (!(activityStartTimeIsAfterCurrentTime === true || canEnrollUntilEnd === true && activityEndTimeIsAfterCurrentTime === true)) {
				console.warn("Event start time is not in future, or (event end time is not in future and eventCategoryId is not enrollUntilEndCategoryId)")
				// Hide button
				return button
			}

			// If user is admin and has hasEventListPermission return true
			//const showAttendeeList = this.user?.isAdmin?.events === true && this.fitnessData.hasEventListPermission === true
			const eventStatus = this.userEventStatus(this.user?.personId, event)

			// If user is not admin and has not EventListPermission
			// Or user AttendanceQuantity is not less than maxAttendeeQuantity, Or user is not in queue
			// if (!(showAttendeeList === true || (this.getTotalUserAttendanceQuantity(event) < this.event?.settings.maxAttendeeQuantity) || eventStatus === 3)) {
			// 	console.warn({showAttendeeList, totalUserAttendanceQuantityLessThanMaxAttendeeQuantity: this.getTotalUserAttendanceQuantity < this.event?.settings.maxAttendeeQuantity, eventStatus})
			// 	return button
			// }

			const isEventEnrollable = this.isEventEnrollable(this.settings, this.fitnessData, event)

			if(isEventEnrollable === false) {
				// Hide button
				return button
			}

			// Future enroll is possible
			if(isEventEnrollable !== true) {
				button.action = "disabled"
				button.icon = "", // TODO: FIND A CLOCK ICON HERE
				button.text =  `${this.$t("wiseFitness.event.enrollmentStartsOn")} <br /> ${isEventEnrollable}` // Enrollment starts on 11.10.2021 16:00
				return button
			}

			// Event is disabled
			if(event.totalCapacity === -1) {
				button.action = "disabled"
				button.text = this.$t("wiseFitness.event.enrollmentDisabled")
				return button
			}



				// Already attending
				if(eventStatus === 1) {
					button.action = "disabled"
					button.text = this.$t("wiseFitness.event.full")
					return button
				}

				// Already in queue
				if(event.currentQueuePosition > 0) {
					button.action = "disabled"
					button.text = `${this.$t("wiseFitness.event.queueAtPosition")}: ${this.eventData?.currentQueuePosition}`
					return button
				}

			// If event is full
			if(!(event.totalCapacity === 0 || event.totalAttendeeQuantity < event.totalCapacity)) {
				// Can join queue
				button.action = "joinqueue"
				button.text = this.$t("wiseFitness.event.joinQueue")
				return button
			}

			// If there is room to enroll
			if(event.totalCapacity === 0 || event.totalAttendeeQuantity < event.totalCapacity) {

				// Enroll and confirm attendance
				if(this.canUserConfirm === true && this.isEventConfirmable(this.settings, event) === true) {
					button.action = "attendregister"
					button.text = this.$t("wiseFitness.event.enrollAndConfirm")
					return button
				}
				else if (this.canUserConfirm === true) {
					console.log("User can enroll but not confirm")
					button.action = "register"
					button.text = this.$t("wiseFitness.event.enroll")
					return button
				}

				// Enroll to event
				button.action = "attend"
				button.text = this.$t('wiseFitness.attendEvent')
				return button
			}
			// Enrollment has closed

			// If we are here fallback is false
			return button
			},

			getTotalUserAttendanceQuantity(event) {
			if (event.eventAttendees === null) return 0;

			return event.eventAttendees.reduce((totalQty, eventAttendee) => {
				return (eventAttendee.personId === this.user.personId) ? totalQty + eventAttendee.quantity : totalQty
			}, 0);
		},

		filterEventsByDate(events) {
			if(this.selectedDate === null) {
				if(this.ownEvents === true) {
					return events.filter(event => event.eventAttendees?.find(attendee => attendee?.personId === this.user?.personId))
				}
				return events
			}
			return events.filter(event => moment(event?.activityStartTime, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY') === this.$filters.dateShort(this.selectedDate))
		},
		isAttendingToEvent(event) {
			return event.eventAttendees?.find(attendee => attendee?.personId === this.user?.personId) ? true : false
		},
		isRegisteredToEvent(event) {
			return event.eventAttendees?.find(attendee => attendee?.personId === this.user?.personId && attendee.registered !== null) ? true : false
		}
	}
};
</script>