import { ref, onMounted } from 'vue'
import { Preferences } from '@capacitor/preferences'
import { toastController } from '@ionic/vue'
import store from '../store/index.js'
import settings from '../WiseSettings.js'
import notifications from '../SystemNotifications.js'
import { _locale } from '../WiseEcom/services/WiseLocale.js'

const checkedSystemNotifications = ref([])

async function checkSystemNotification(id) {
    checkedSystemNotifications.value.push(id)
    // preferences get deleted when user logs out and we see the notifications again, not good..
    await Preferences.set({ 
        key: `checked-system-notifications-${ settings.appId }`,
        value: JSON.stringify(checkedSystemNotifications.value)
    })
}

async function loadCheckedSystemNotifications() {
    try {
        const { value } = await Preferences.get({ key: `checked-system-notifications-${ settings.appId }` })

        if (!value) return;

        checkedSystemNotifications.value = JSON.parse(value)
    } catch(e) {
        console.log('failed to read system notifications', e)
    }
}

async function createNotificationToast(notification) {
    const {
        id,
        title,
        content,
        color,
        callback
    } = notification
    const buttons = [
        {
            side: 'end',
            text: _locale('close'),
            role: 'cancel',
            handler: () => {
                checkSystemNotification(id)
            }
        }
    ]

    if (typeof callback === 'function') {
        buttons.push({
            side: 'end',
            text: _locale('notifications.open'),
            handler: () => {
                checkSystemNotification(id)
                callback()
            }
        })
    }

    const toast = await toastController.create({
        header: title,
        message: content,
        position: 'top',
        color,
        buttons,
    })
    await toast.present();
}

async function triggerNotifications(topic) {
    const userLocale = store.getters['common/userLocale']
    const key = `${ topic }-${ userLocale.split('-')[0] }`
    
    if (!notifications[key]) return;

    await loadCheckedSystemNotifications()

    const checked = checkedSystemNotifications.value
    const availableNotifications = notifications[key]
        .filter(row => row.expired === false)
        .filter(row => checked.includes(row.id) === false)

    availableNotifications.forEach((row) => createNotificationToast(row))
}

export function useSystemNotifications() {
    onMounted(() => {
        triggerNotifications('default')
    })
    
    return {
        triggerNotifications,
    }
}