import { 
    addMinutes, addHours, addSeconds,
    differenceInMinutes,
    startOfHour, endOfHour,
    isBefore,
    format
} from 'date-fns'

/**
 * To be used where moment is too heavy
 * This assumes every date is in YYYY-MM-DD HH:mm:ss format!!
 * 
*/
export class WiseDate extends Date {
    /**
     * Tries to mimic moment syntax but does not support everything!
     * 
     * @param {WiseDate|Date|String} data 
     * @returns {WiseDate}
     */
    constructor(data = '') {
        let args = [];

        if (typeof data.toDate === 'function') {
            args = [ data.toDate() ];
        } if (data instanceof Date) {
            args = [ data ];
        } else if (typeof data === 'string' && data.length > 0) {
            try {
                const pieces = data.split(' ');
                let [ year, month, day ] = pieces[0].split('-');
                
                if (typeof pieces[1] === 'string') {
                    let [ hours, minutes, seconds ] = pieces[1].split(':');
                    args = [
                        parseInt(year),
                        parseInt(month) - 1,
                        parseInt(day),
                        parseInt(hours) || 0,
                        parseInt(minutes) || 0,
                        parseInt(seconds) || 0
                    ];
                } else {
                    args = [
                        parseInt(year),
                        parseInt(month) - 1,
                        parseInt(day),
                    ];
                }
            } catch(e) {
                console.error(e)
            }
        }

        super(...args)

        return this;
    }

    /**
     * 
     * @param {Number} amount 
     * @param {String} pattern 
     * @returns {WiseDate}
     */
    add(amount, pattern) {
        switch (pattern) {
            case 'seconds':
                return new WiseDate(addSeconds(this, amount));
            case 'minutes':
                return new WiseDate(addMinutes(this, amount));
            case 'hours':
                return new WiseDate(addHours(this, amount));
            default:
                console.log('WiseDate - add format is not supported yet:', pattern)
                return this;
        }
    }

    /**
     * 
     * @param {Date|WiseDate} date 
     * @param {String} pattern 
     * @returns {Number}
     */
    diff(date, pattern) {
        switch (pattern) {
            case 'minutes':
                //console.log(differenceInMinutes(this, date), this, date)
                return differenceInMinutes(this, date)
            default:
                console.log('WiseDate - diff format is not supported yet:', pattern)
                return 0;
        }
    }

    isBefore(date) {
        return isBefore(this, date)
    }

    /**
     * 
     * @param {String} pattern 
     * @returns {WiseDate}
     */
    startOf(pattern) {
        switch (pattern) {
            case 'hours':
                return new WiseDate(startOfHour(this));
            default:
                console.log('WiseDate - startOf format is not supported yet:', pattern)
                return 0;
        }
    }

    /**
     * 
     * @param {String} pattern 
     * @returns {WiseDate}
     */
     endOf(pattern) {
        switch (pattern) {
            case 'hours':
                return new WiseDate(endOfHour(this));
            default:
                console.log('WiseDate - startOf format is not supported yet:', pattern)
                return 0;
        }
    }

    /**
     * 
     * @param {String} pattern 
     * @returns {Mixed}
     */
    format(pattern) {
        switch (pattern) {
            case 'x':
                return this.getTime();
            case 'X':
                return parseInt(this.getTime() / 1000);
            default:
                return format(
                    this, 
                    pattern
                    .replace('YYYY', 'yyyy') // full year
                    .replace(/\bDD\b/, 'dd') // day of month, short
                    .replace('dddd', 'cccc') // day of week, long
                );
        }
    }

    /**
     * 
     * @returns {Number}
     */
    day() {
        return this.getDay();
    }

    /**
     * Just mimic the syntax, don't do anything..
     * @returns {WiseDate}
     */
    set() {
        return this;
    }

    /**
     * 
     * @returns {WiseDate}
     */
    clone() {
        return new WiseDate(this);
    }
}

// for quickly replacing a moment instance
export function wnmoment(date) {
    return new WiseDate(date);
}