// this should be replaced with the i18n $t function in the future
let translate = (placeholder) => placeholder;

export function setLocaleFunction(func) {
    translate = func;
}

export function _locale(string, replaceWith = null) {
    if (typeof string !== 'string') {
        console.error('_locale was called with a', typeof string, 'value');
        return '';
    }
    let returnString = translate(string);

    if (typeof replaceWith !== 'undefined') {
        if (Array.isArray(replaceWith)) {
            returnString = returnString.replace(/%s|%d/g, function() {
                return replaceWith.shift();
            });
        } else {
            returnString = returnString.replace(/%s|%d/g, replaceWith);
        }
    }
    
    return returnString;
}