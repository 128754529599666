<template>
	<div class="height-100">
		<ion-content class="ion-padding ion-text-center">
			<div class="center center-h100">
				<div class="wise-picker-wrapper">
					<h3 class="ion-no-margin ion-margin-bottom">{{ $t("wiseGymcard.eventConfirmPicker.openEvent") }}</h3>
					<!-- EVENT PICKER -->
					<ion-button v-for="event in events" :key="event.activityId" class="event-picker-button btn-border-none ion-margin-bottom" color="dark" expand="block" @click="() => [$router.push(`fitness/event/${event.activityId}`), closeModal()]">
						{{ event.name }}<br />{{ $filters.eventFormatDate(event, true) }}
					</ion-button>
					<ion-button class="btn-border-none" color="danger" shape="round" expand="block" @click="closeModal()">{{ $t("cancel")}}</ion-button>
				</div>
			</div>
		</ion-content>
	</div>
</template>

<script>
import { modalController } from "@ionic/vue";

export default {
	name: "WiseEventConfirmPicker",
	props: {
		events: {
			default: null
		},
	},
	mounted() {
		console.log(this.events)
	},
	methods: {
		closeModal() {
			modalController.dismiss();
		},
	},
};
</script>

<style scoped>
	ion-content {
		--background: transparent /* Modal background */
	}

	ion-button.event-picker-button::part(native) {
		line-height: 23px;
	}
	ion-button.event-picker-button {
		font-weight: normal;
		font-style: normal;
		text-transform: capitalize;
		font-family: inherit;
		height: auto;
		--padding-bottom: 10px;
		--padding-top: 10px;
	}

	h6 {
		font-weight: normal;
		margin-top: 5px;
	}
	.wise-picker-wrapper {
		background: #fff;
    width: 80%;
    padding: 20px;
    color: #000;
	position: absolute;
		top: 10%;
		left: 10%;
	}
</style>