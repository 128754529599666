import { createLogger, createStore } from "vuex"
import settings from '@/WiseSettings.js';

const store = createStore({
  plugins: settings.vuexLogger ? [createLogger()] : [],
  state: {},
  getters: {},
  mutations: {},
  actions: {}
})

export default store;