import { ref } from 'vue'
// data and functions for app state that are not Wise-specific
export const appState = {
    runners: ref([]),
}

export function randomId() {
    const randomHex = () => Math.floor(Math.random()*16777215).toString(16)

    return `${ randomHex() }-${ randomHex() }-${ randomHex() }-${ randomHex() }`
}

/**
 * 
 * @param {Function} runner a function to run on app change
 * @param {Object} settings
 * @returns {Function}
 */
export function addRunner(runner, settings = { isActive: true }) {
    const id = randomId()
    
    appState.runners.value.push({
        id,
        method: runner,
        ...settings,
    })

    return () => {
        const index = appState.runners.value.findIndex(row => row.id === id)

        appState.runners.value.splice(index, 1)
    }
}

/**
 * 
 * @param {Object} settings 
 */
export function run(settings = { isActive: true }) {
    const runners = appState.runners.value.filter(row => {
        const conditions = Object.keys(row).filter(name => ['method','id'].includes(name) === false);
        
        for (let i = 0;i < conditions.length;i++) {
            const name = conditions[i];

            if (settings[name] !== row[name]) {
                return false;
            }
        }

        return true;
    });

    runners.forEach((row, i) => {
        row.method();

        if (row.once) {
            appState.runners.splice(i, 1);
        }
    })
}